/**
 * Foursource page
 * https://foursource.com/privacy-policy-members
 */

import * as React from 'react';
import { withTrans } from '../i18n/withTrans';

import { pageTexts } from '../content/privacy-policy-members';

// components
import Layout from '../components/layout';
import TitleSeparator from '../components/titleSeparator';
import Text from '../components/text';

class privacyPolicyMembersPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  render() {
    return (
      <Layout pageName='privacy-policy-members' whiteMenu={true}>
        <div className='pt-menu'>
          <div className='paddingSidesBig pb-75 pt-75'>
            <TitleSeparator info={this.text.topTitle}></TitleSeparator>
            <Text text={this.text.terms}></Text>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withTrans(privacyPolicyMembersPage);
